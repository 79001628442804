module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Words Online","short_name":"Words Online","lang":"en","start_url":"/","background_color":"#535353","theme_color":"#535353","display":"standalone","icon":"src/images/icon.png","localize":[{"start_url":"/ru/","lang":"ru","name":"Слова Онлайн","short_name":"Слова Онлайн"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-42583312-4","head":false,"anonymize":true},
    }]
