import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LocaleContext from "../localeContext"

function useTranslations() {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = React.useContext(LocaleContext)
  // Query the CSV file in <rootDir>/data/translations
  const { allTranslationsCsv } = useStaticQuery(query)

  // Simplify the response from GraphQL
  let translations = {};
  allTranslationsCsv.edges.forEach(item => {
    if (!translations[item.node.lang]) {
      translations[item.node.lang] = {};
    } 
    translations[item.node.lang][item.node.key] = item.node.value;
  })

  // Only return translations for the current locale
  return translations[locale];
}

export default useTranslations

const query = graphql`
query useTranslations {
  allTranslationsCsv {
    edges {
   	  node {
        lang
        key
        value
      }
    }
  }
}
`
