import React from "react"
import useTranslations from "./useTranslations"
import LocaleContext from "../localeContext"

import { FaFacebookSquare, FaVk } from "react-icons/fa"

import logoImageDefault from "../images/wordsonline-footer-logo.png"
import logoImageRu from "../images/wordsonline-footer-logo.ru.png"

const Footer = () => {
  const { copyright } = useTranslations()

  const year = new Date().getUTCFullYear()

  const { locale } = React.useContext(LocaleContext)
  const logoImage = locale == "ru" ? logoImageRu : logoImageDefault

  return (
    <footer className="footer">
      <div className="container has-text-centered">
        <div className="block is-centered">
          <img src={logoImage} alt="" style={{ maxWidth: "256px" }} />
        </div>
        <div className="buttons is-centered">
          <a className="button" href="https://vk.ru/wordsonline">
            <span className="icon">
              <FaVk size={32} />
            </span>
          </a>
          <a className="button" href="https://fb.me/wordsonline">
            <span className="icon">
              <FaFacebookSquare size={32} />
            </span>
          </a>
        </div>
        <p className="subtitle is-6">
          &copy; {year} {copyright}
          <br />
          <a href="https://www.indiesoft.by/" target="_blank">
            www.indiesoft.by
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
