import React from "react"

import "../style.scss"

import Header from "../components/header"
import Footer from "../components/footer"

import withI18next from "../i18n/withI18next"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default withI18next()(Layout)
