import React from "react"
import { Link } from "gatsby"
import LocalizedLink from "./localizedLink"
import useTranslations from "./useTranslations"

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isToggleOn: false }

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn,
    }))
  }

  render() {
    const {
      headerGameTitle,
      headerHowToPlay,
      headerPlayButton,
      backToHome,
    } = this.props.translations

    return (
      <nav className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <LocalizedLink
              className="navbar-item"
              to="/"
              aria-label={backToHome}
            >
              {headerGameTitle}
            </LocalizedLink>
            <a
              className={
                this.state.isToggleOn
                  ? "navbar-burger is-active"
                  : "navbar-burger"
              }
              role="button"
              aria-label="menu"
              aria-expanded="false"
              onClick={this.handleClick}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div
            className={
              this.state.isToggleOn ? "navbar-menu is-active" : "navbar-menu"
            }
          >
            <div className="navbar-start">
              {/* <LocalizedLink className="navbar-item" to="/test/">
              {headerHowToPlay}
            </LocalizedLink> */}
            </div>
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <Link to="/" hrefLang="en" className="button">
                    English
                  </Link>
                  <Link to="/ru/" hrefLang="ru" className="button">
                    Русский
                  </Link>
                  <a className="button is-primary" disabled href="#">
                    {headerPlayButton}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

const Header = () => {
  return <Navbar translations={useTranslations()}></Navbar>
}

export default Header
